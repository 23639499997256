import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';

import { VscCode } from 'react-icons/vsc';
import { BsServer } from 'react-icons/bs';
import { BiGitBranch } from 'react-icons/bi';


const About = ({ theme }) => {

    return (
        <div id = "about" className = "cool-about" style = {{backgroundColor: theme}} >
            <Container fluid = "md" className = "about-container">
                <Row xs = {1} md = {1}>
                    <Col className = "about-con-col-one">
                        <Row style = {{justifyContent: 'center', width: '800px'}}>
                            <h1>Hi, I'm Griffin. Nice to meet you!</h1>
                            <p>
                                Since beginning my journey as a film major nearly 10 years ago, I made the switch to become a web developer.
                                I've collaborated with talented peers, friends, and family to help create educational projects to enhance my knowledge.
                                I'm quietly confident, naturally observant, and constantly working on improving my apps one bug at a time.
                            </p>
                            <CardGroup style = {{marginTop: '20px'}}>
                                <Card style = {{color: theme}}>
                                    <Card.Header>
                                        <VscCode size = {24} />
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>Front End</Card.Title>
                                        <Card.Text>
                                            <p>HTML, CSS, JavaScript, JQuery, React</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style = {{color: theme}}>
                                    <Card.Header>
                                        <BsServer size = {24} />
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>Back End</Card.Title>
                                        <Card.Text>
                                            <p>Python, Java, Node</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style = {{color: theme}}>
                                    <Card.Header>
                                        <BiGitBranch size = {24} />
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>Frameworks</Card.Title>
                                        <Card.Text>
                                            <p>Django, Express, Flask, Spring Boot, VsCode, Bootstrap, Material UI</p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default About;
