import React from 'react';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar';

import { FaGithub, FaLinkedin } from 'react-icons/fa'


const Footer = ({ theme }) => {

    return (
        <Navbar expand = "md">
            <Container fluid = "md">
                <Navbar.Brand>
                    <a
                        style = {{color: theme}}
                        href = "https://github.com/griffinbm"
                    >
                    <FaGithub />
                    </a>{' '}
                    <a
                        style = {{color: theme}}
                        href = "https://www.linkedin.com/in/gbmcmanus/"
                    >
                    <FaLinkedin />
                    </a>
                </Navbar.Brand>
                <Navbar.Text>
                    <a href="#top" style = {{color: theme}}> Back To Top</a>
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}

export default Footer;