import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';

import CSM_Badge from '../img/csm_badge.png'
import CSD_Badge from '../img/csd_badge.png'
import ACSD_Badge from '../img/acsd_badge.png'


const Certifications = ({ theme }) => {

    return (
        <div id = "certifications" className = "cool-certifications" style = {{backgroundColor: theme}}>
            <Container fluid = "md" className = "certifications-container">
                <Row xs = {1} md = {1}>
                    <Col className = "certifications-con-col-one">
                        <Row style = {{justifyContent: 'center', width: '800px'}}>
                            <h1>Certifications</h1>
                            <CardGroup style = {{marginTop: '20px', color: theme}}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Scrum Master</Card.Title>
                                        <Card.Img src = {CSM_Badge} className = "cert-img" />
                                        <Card.Text>
                                            
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                            <a 
                                                style = {{color: theme}}
                                                href = "http://bcert.me/siufxkjyg"
                                            >
                                                Badge
                                            </a>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Scrum Developer</Card.Title>
                                        <Card.Img src = {CSD_Badge} className = "cert-img" />
                                        <Card.Text>
                                            
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <a 
                                            style = {{color: theme}}
                                            href = "https://bcert.me/srrgpqxcb"
                                        >
                                            Badge
                                        </a>
                                    </Card.Footer>
                                </Card>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Adv. Scrum Developer</Card.Title>
                                        <Card.Img src = {ACSD_Badge} className = "cert-img" />
                                        <Card.Text>
                                            
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <a
                                            style = {{color: theme}}
                                            href = "https://bcert.me/sodjzjzmz"
                                        >
                                            Badge
                                        </a>
                                    </Card.Footer>
                                </Card>
                            </CardGroup>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Certifications;
