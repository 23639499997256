import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const Projects = ({ theme }) => {
    
    return (
        <div id = "projects" className = "cool-projects">
            <Container fluid = "md" className = "projects-container" style = {{color: theme}}>
                <Row xs = {1} md = {1}>
                    <Col className = "projects-con-col-one">
                        <Row style = {{justifyContent: 'center', width: '800px'}}>
                            <h1>Projects</h1>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Projects;
