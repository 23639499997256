import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';

import { FaAlignRight } from 'react-icons/fa';



const Navigation = (props) => {
    const {theme, onChange} = props;
    return (
        <Navbar id = "#top" expand = "lg" className = "navibar" style = {{borderBottom: '1px solid ' + (theme)}}>
            <Container fluid = "lg">
                <Navbar.Brand href="#home" style = {{color: theme}}>
                    Griffin McManus
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <FaAlignRight className = "menu-toggle" color = {theme}/>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Nav.Link href="#about" style = {{color: theme}}>About</Nav.Link>
                        <Nav.Link href="#projects" style = {{color: theme}}>Projects</Nav.Link>
                        <Nav.Link href="#certifications" style = {{color: theme}}>Certifications</Nav.Link>
                        <Form.Control
                            type="color"
                            id="exampleColorInput"
                            defaultValue={theme}
                            title="Choose your color"
                            onChange = {onChange}
                            style = {{margin: '0px auto'}}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;
