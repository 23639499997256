import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import Navigation from './components/Navigation';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Certifications from './components/Certifications';
import Footer from './components/Footer';

function App() {
  const [theme, setTheme] = useState("#3DA473")

  const onChangeHandler = e => {
    setTheme(e.target.value);
  }

  return (
    <div className="App">
      <Navigation theme = {theme} onChange = {onChangeHandler}/>
      <Header theme = {theme}/>
      <About theme = {theme} />
      <Projects theme = {theme} />
      <Certifications theme = {theme} />
      <Footer theme = {theme} />
    </div> 
  );
}

export default App;
