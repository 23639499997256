import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Man from '../img/man.png';

const Header = ({ theme }) => {
    return (
        <Container fluid = "md" className = "header-container" style = {{color: theme}}>
            <Row xs = {1} md = {1}>
                <Col className = "header-con-col-one">
                    <Row>
                        <h1>Web Developer & Coffee Drinker </h1>
                        <h4>I design and code educational projects, I love what I do.</h4>
                        <div className = "header-img-box">
                            <img src = {Man} alt = "selfie" className = "header-img" />
                        </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Header;
